import React, { useRef, useState, useEffect } from 'react'  
import AdminServiciosAPI from '../servicios/AdminServicios';
import './header.css';
import { useCookies } from 'react-cookie';
import { Menu } from 'primereact/menu';
import { Dropdown } from 'primereact/dropdown';

const Header = () => {  
	const menu = useRef(null);
	const [cookies, setCookie, removeCookie] = useCookies(['user']);
	const [materias, setMaterias] = useState(null);
	const [materia, setMateria] = useState(null);
	const items = [
		{
			label: 'Salir',
			icon: 'pi pi-sign-out',
			command: () => {cerrarSesion()}
		}
	];

	useEffect( () => {
		if(cookies.CRCOOKIEMATERIA){
			setMateria(cookies.CRCOOKIEMATERIA);
		}
		if(!cookies.CRUSUARIO.tipo){
			cargarMaterias();
		}
	}, []);

	async function cargarMaterias() {
		const response = await AdminServiciosAPI.listadoRegistros(cookies.CRCOOKIEUSUARIO,'materias');
		if(response.ok){
			const listaMaterias = await response.json();
			if(listaMaterias.length > 0){
				setMaterias(listaMaterias);
			}
		}else{
			const error = await response.json();
			console.log('error cursos',error);
		}
	};

	function cerrarSesion() {
		removeCookie('CRUSUARIO', { path: '/' });
		removeCookie('CRCOOKIEUSUARIO', { path: '/' });
		removeCookie('CRCOOKIEMATERIA', { path: '/' });
		window.location.href='/';
	}

	const onMateriaSelccion = (e) => {
		setCookie('CRCOOKIEMATERIA', e.value, { path: '/' });
		window.location.reload(false);
    }

	return (
		<div className="header" id="heading">
			<div className="header-left">
				{!cookies.CRUSUARIO.tipo && materias && <Dropdown appendTo="self" value={materia} options={materias} onChange={onMateriaSelccion} optionLabel="nombre" filter showClear filterBy="nombre" placeholder="Seleccione un aula"/>}
			</div>
			<a id="toggle_btn" href="#">
				<span className="bar-icon">
					<span></span>
					<span></span>
					<span></span>
				</span>
			</a>
			<a id="mobile_btn" className="mobile_btn" href="#"><i className="fa fa-bars"></i></a>
			
			<ul className="nav user-menu">

				<li className="nav-item dropdown has-arrow main-drop">
				<Menu model={items} popup ref={menu} id="popup_menu" />
				<a href="#" onClick={(event) => menu.current.toggle(event)} className="dropdown-toggle nav-link" data-toggle="dropdown">
					<span className="user-img"><img src={process.env.PUBLIC_URL +'/assets/img/system-user.png'}/>
					<span className="status online"></span></span>
					<span>{cookies.CRUSUARIO.nombre}</span>
				</a>
				</li>
			</ul>
			
			<div className="dropdown mobile-user-menu">
				<a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
				<div className="dropdown-menu dropdown-menu-right">
					
				</div>
			</div>
			
		</div>
	);
}

export default Header