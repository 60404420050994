import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Layout from './core/Layout';
import Login from './core/Login';
import PrimeReact from 'primereact/api';
import { useCookies } from 'react-cookie';
import {
  BrowserRouter as Router
} from "react-router-dom";

PrimeReact.ripple = true;

function App() {
  const [cookies] = useCookies(['user']);

  return (
    <Router>
      <div className="App">
        {cookies.CRUSUARIO ? <Layout/>:<Login/>}
      </div>
    </Router>
  );
}

export default App;
