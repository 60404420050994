import React from 'react'  
import { Switch, Route } from "react-router-dom";
import Entrar from '../login/Entrar';
import EntrarTutor from '../login/EntrarTutor';

function Login () {     
    return (
        <div class="main-wrapper">
            <div class="account-content">
                
                <div class="container">

                    <div class="account-box">
                        <div class="account-wrapper">
                        <Switch>
                            <Route path="/tutor/:idmateria">
                                <EntrarTutor/>
                            </Route>
                            <Route path="/">
                                <Entrar/>
                            </Route>
                        </Switch>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login