import { request } from'./Omni';
import { Config } from '../comun';

const AdminServiciosAPI = {
    login: async ({usuario, clave}) => {
      const _url = `${Config.apiGateway}/usuarios`;
      return request(_url, {
        method: "POST",
        body: JSON.stringify({
          usuario:usuario,
          clave:clave,
        }),
      });
    },
    loginTutor: async ({usuario, clave, idmateria}) => {
      const _url = `${Config.apiGateway}/usuarios/tutor/${idmateria}`;
      return request(_url, {
        method: "POST",
        body: JSON.stringify({
          usuario:usuario,
          clave:clave,
        }),
      });
    },
    listadoRegistros: async (cookieusuario,parametro) => {
      const _url = `${Config.apiGateway}/${parametro}`;
      return request(_url, {
        method: "GET",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario},
      });
    },
    getMateria: async (cookieusuario,id) => {
      const _url = `${Config.apiGateway}/materias/${id}`;
      return request(_url, {
        method: "GET",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario},
      });
    },
    getItems: async (cookieusuario,tabla,parametros) => {
      const _url = `${Config.apiGateway}/${tabla}/items`;
      return request(_url, {
        method: "POST",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario},
        body: JSON.stringify({
          parametros:parametros,
        }),
      });
    },
    getModulo: async (cookieusuario,idmateria,id) => {
      const _url = `${Config.apiGateway}/modulos/${idmateria}/${id}`;
      return request(_url, {
        method: "GET",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario},
      });
    },
    nuevoRegistro: async (cookieusuario,tabla,parametros) => {
      const _url = `${Config.apiGateway}/${tabla}`;
      return request(_url, {
        method: "PUT",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario},
        body: JSON.stringify(parametros),
      });
    },
    editarRegistro: async (cookieusuario,tabla,parametros) => {
      const _url = `${Config.apiGateway}/${tabla}`;
      return request(_url, {
        method: "POST",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario},
        body: JSON.stringify(parametros),
      });
    },
    eliminarRegistro: async (cookieusuario,tabla,parametros) => {
      const _url = `${Config.apiGateway}/${tabla}`;
      return request(_url, {
        method: "DELETE",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario},
        body: JSON.stringify(parametros),
      });
    },
    getModulosLecciones: async (cookieusuario,idmateria) => {
      const _url = `${Config.apiGateway}/modulos/${idmateria}`;
      return request(_url, {
        method: "GET",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario},
      });
    },
    getTema: async (cookieusuario,id) => {
      const _url = `${Config.apiGateway}/temas/${id}`;
      return request(_url, {
        method: "GET",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario},
      });
    },
    getEstudiante: async (cookieusuario,id) => {
      const _url = `${Config.apiGateway}/estudiantes/${id}`;
      return request(_url, {
        method: "GET",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario},
      });
    },
    enviarArchivo: async (cookieusuario,url,parametros) => {
      const _url = `${Config.apiGateway}/files/${url}`;
      return request(_url, {
        method: "POST",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario},
        body: JSON.stringify(parametros),
      });
    },
    getUrlSubirArchivo: async (cookieusuario,url) => {
      const _url = `${Config.apiGateway}/files/${url}`;
      return request(_url, {
        method: "GET",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario}
      });
    },
    getOrdenTema: async (cookieusuario,idmateria,idleccion) => {
      const _url = `${Config.apiGateway}/temas/orden/${idmateria}/${idleccion}`;
      return request(_url, {
        method: "GET",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario},
      });
    },
}

export default AdminServiciosAPI;