import React, { useState, useRef } from 'react'  
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useCookies } from 'react-cookie';
import { Messages } from 'primereact/messages';
import AdminServiciosAPI from '../servicios/AdminServicios';

function Entrar() {  
    const [usuario, setUsuario] = useState('');
    const [clave, setClave] = useState('');
    const [, setCookie] = useCookies(['user']);
    const mensaje = useRef(null);
    const [cargando, setCargando] = useState(false);

    const _entrar = async (usuario,clave) => {
        setCargando(true);
        const response = await AdminServiciosAPI.login({usuario,clave});
        if(response.error){
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: `Lo sentimos tenemos problemas con red, intente en unos minutos.`
            });
        }else if(!response.ok){
            const errormsj = await response.json();
            mensaje.current.show({
                severity: 'info',
                sticky: true,
                detail: errormsj
            });
        }else{
            const datosUsuario = await response.json();
            datosUsuario.usuario.datos = JSON.parse(datosUsuario.usuario.datos);
            setCookie('CRUSUARIO', datosUsuario.usuario.datos, { path: '/' });
            setCookie('CRCOOKIEUSUARIO', datosUsuario.cookie, { path: '/' });
            window.location.reload(false);
        }
        setCargando(false);
    }

    return (
        <div>
            <Messages ref={mensaje} />
            <form className="form-horizontal">
                <div class="form-group">
                    <label>Usuario</label>
                    <InputText className="form-control p-inputtext" value={usuario} onChange={(e) => setUsuario(e.target.value)} />
                </div>
                <div class="form-group">
                    <label>Clave</label>
                    <InputText className="form-control p-inputtext" type="password" value={clave} onChange={(e) => setClave(e.target.value)} />
                </div>
                <div class="form-group text-center">
                    <Button type="button" loading={cargando} className="btn btn-primary account-btn" label="Entrar" onClick={() =>_entrar(usuario,clave)} />
                </div>
            </form>
        </div>
    )
}

export default Entrar