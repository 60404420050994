import React, {lazy, Suspense} from 'react';
import './layout.css';
import Header from './Header';
import MenuLeft from './Menu';
import { Switch, Route } from "react-router-dom";
import { useCookies } from 'react-cookie';

const Tutoria = lazy(() => import('../mantenimiento/Tutoria'));
const Faq = lazy(() => import('../materia/Faq'));
const Visualizaciones = lazy(() => import('../reportes/Visualizaciones'));
const EstudianteAvance = lazy(() => import('../reportes/EstudianteAvance'));
const Notificaciones = lazy(() => import('../mantenimiento/Notificaciones'));
const MenuPrincipal = lazy(() => import('../materia/MenuPrincipal'));
const Modulos = lazy(() => import('../mantenimiento/Modulos'));
const Estudiantes = lazy(() => import('../mantenimiento/Estudiante'));
const Materia = lazy(() => import('../mantenimiento/Materia'));
const Temas = lazy(() => import('../mantenimiento/Temas'));
const Certificados = lazy(() => import('../certificado/Certificado'));
const Firmas = lazy(() => import('../certificado/Firmas'));

const Layout = () => {  
    const [cookies] = useCookies(['user']); 

    const OpcionesAdmin = () => {
        return <Switch>
            <Route path="/reportes/visualizaciones">
                <Suspense fallback={<div>Cargando...</div>}>
                    <Visualizaciones />
                </Suspense>
            </Route>
            <Route path="/reportes/estudiantes">
                <Suspense fallback={<div>Cargando...</div>}>
                    <EstudianteAvance />
                </Suspense>
            </Route>
            <Route path="/certificados/firmas">
                <Suspense fallback={<div>Cargando...</div>}>
                    <Firmas cookies={cookies}/>
                </Suspense>
            </Route>
            <Route path="/certificados/Firmar">
                <Suspense fallback={<div>Cargando...</div>}>
                    <Certificados cookies={cookies}/>
                </Suspense>
            </Route>
            <Route path="/modulos">
                <Suspense fallback={<div>Cargando...</div>}>
                    <Modulos />
                </Suspense>
            </Route>
            <Route path="/temas">
                <Suspense fallback={<div>Cargando...</div>}>
                    <Temas />
                </Suspense>
            </Route>
            <Route path="/estudiantes">
                <Suspense fallback={<div>Cargando...</div>}>
                    <Estudiantes />
                </Suspense>
            </Route>
            <Route path="/menuprincipal">
                <Suspense fallback={<div>Cargando...</div>}>
                    <MenuPrincipal cookies={cookies} />
                </Suspense>
            </Route>
            <Route path="/faq">
                <Suspense fallback={<div>Cargando...</div>}>
                    <Faq cookies={cookies}/>
                </Suspense>
            </Route>
            <Route path="/tutorias">
                <Suspense fallback={<div>Cargando...</div>}>
                    <Tutoria />
                </Suspense>
            </Route>
            <Route path="/notificaciones">
                <Suspense fallback={<div>Cargando...</div>}>
                    <Notificaciones />
                </Suspense>
            </Route>
            <Route path="/">
                <Suspense fallback={<div>Cargando...</div>}>
                    <Materia />
                </Suspense>
            </Route>
        </Switch>
    }

    const OpcionesTutor = () => {
        return <Switch>
            <Route path="/">
                <Suspense fallback={<div>Cargando...</div>}>
                    <Tutoria />
                </Suspense>
            </Route>
        </Switch>
    }

    return (
        <div className="main-wrapper">
            <MenuLeft/>
            <Header/>
            <div className="page-wrapper">
                <div className="content container-fluid">
                {cookies.CRCOOKIEMATERIA?cookies.CRUSUARIO.tipo?<OpcionesTutor/>:<OpcionesAdmin/>:''}
                </div>
            </div>
        </div>
    )
}

export default Layout 