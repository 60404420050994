/**
 * An async fetch with error catch
 * @param url
 * @param data
 * @returns {Promise.<*>}
 */
export const request = async (url, data = {}) => {
    try {
      const response = await fetch(url, data);
      return response;
    } catch (err) {
      console.log(err);
      return { error: err };
    }
};

export const getLabelfromValue = (array,key) => {
  const objIndex = array.findIndex((obj => obj.value === key));
  return array[objIndex].label;
}

export const formatDate = (date) => {  
  if (!(date instanceof Date)) {
    throw new Error('Invalid "date" argument. You must pass a date instance')
  }

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const obtenerIdNuevo = (lista) => {
  let idnuevo = Math.floor(Math.random() * 100);
  let objIndex = lista.findIndex((obj => obj.id === idnuevo));
  if(objIndex>=0){
      return obtenerIdNuevo(lista);
  }else{
      return idnuevo;
  }
}

export function b64ToURL(base64Data) {
  return new File(
    [Uint8Array.from(atob(base64Data), (m) => m.codePointAt(0))],
    'certificado.pdf',
    { type: "application/pdf" }
 );
}

export function blobToURL(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
}