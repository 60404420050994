import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

const InputDatoPerfil = ({id,dato,index,setValorDato}) => {
    let input;
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Claro'
    });
    switch (dato.tipo) {
        case 'c':
            input = <InputText onChange={(e) => setValorDato(e.target.value,index)} id={id} className="p-d-block form-control" value={dato.valor} maxLength="100" keyfilter="email"/>
            break;
        case 'f':
            const valorfecha = dato.valor?new Date(dato.valor):null;
            input = <Calendar onChange={(e) => setValorDato(e.value,index)} id={id} value={valorfecha} locale="es" dateFormat="dd/mm/yy" mask="99/99/9999" />
            break;
        case 'w':
        case 'p':
            input = <InputText onChange={(e) => setValorDato(e.target.value,index)} id={id} className="p-d-block form-control" value={dato.valor} maxLength="20" keyfilter="num"/>
            break;
        default:
            input = <InputText onChange={(e) => setValorDato(e.target.value,index)} id={id} className="p-d-block form-control" value={dato.valor} maxLength="100"/>
            break;
    }
    return input;
}

export default InputDatoPerfil;