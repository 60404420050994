const Constantes = {
    tituloSitio: "Administración",
    sitename: "administrador",
    estadosPublicado: ["Publicado", "No Publicado"],
    estadoActivo: "ACT",
    estadoInactivo: "INA",
    estadoAfirmacion: ["Si","No"],
    estRegistro: [
        {label: 'Activo', value: 'ACT'},
        {label: 'Inactivo', value: 'INA'}
    ],
    estEstudiante: [
        {label: 'Registrado', value: 1},
        {label: 'Pagado', value: 2},
        {label: 'Inactivo', value: 3},
    ],
    tipoDatoPerfil:[
        {label: 'Texto', value:'t'},
        {label: 'Correo', value:'c'},
        {label: 'Whatsapp', value:'w'},
        {label: 'Teléfono', value:'p'},
        {label: 'Url', value:'u'},
        {label: 'Fecha', value:'f'},
        //{label: 'Imagen', value:'i'},
    ],
    planProfesional: 'U',
    planBeta: 'P',
    planBasico: 'B'
}

export default Constantes;